import React from "react";
import distiAuth from "disti-auth.js";
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicIcon from '@material-ui/icons/Mic';
import CustomButton from "components/CustomButtons/Button.js";
import CallEndIcon from '@material-ui/icons/CallEnd';
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";

import ConfirmDialog from './ConfirmDialog.js';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const cleanupData = {
    cleanupVideo: null,
    closeConnectionsFunc: null
}

const getKinesisAccessData = async({region, natTraversalDisabled, forceTURN, isMaster, studentId})=>
{ 
    return {
        
        requestSigner: { 
            getSignedURL: async ( endpoint, queryParams ) =>
                {
                    const rval = await distiAuth.getSignedKinesisURL({region, endpoint, queryParams})
                    return rval
                }
        },
        
        data: await distiAuth.getKinesisAccessData({region, natTraversalDisabled, forceTURN, isMaster, studentId})            
            
    }
    
}
const useViewMaster = ({localVideoRef, remoteVideoRef, onIncomingMessage, sendMessageRef, studentId,
                        sendAudio, sendVideo, onIncomingCall, onConnected, onDisconnected,
                        onConfirmConnection, classRegion})=>
{
    studentId = studentId || ""
    
    const userType = distiAuth.getUserType()
    const username = distiAuth.getUsername() 
    
    const region = classRegion
    //const [region, setRegion] = React.useState('us-east-1')
    
    const [letsRestart, setLetsRestart] = React.useState(false)
    
    const [videoServerError, setVideoServerError] = React.useState(false)
    
    const [clientId] = React.useState(""+Math.random()) // Keep the same random id on render

    const muteFuncRef = React.useRef(null)
    
    //let channelARN = ""; //'arn:aws:kinesisvideo:us-east-1:773886541566:channel/test-channel/123';
    const onRemoteDataMessage = (message)=>
    {
        if (onIncomingMessage)
        {
            onIncomingMessage(JSON.parse(message.data));
        }
    }    
    const mutedState = {value: false}
    const setMuted = (newValue)=>
    {
        mutedState.value = newValue
        if (muteFuncRef.current)
        {
            muteFuncRef.current(newValue)
        }        
    }
    const natTraversalDisabled = false
    const forceTURN = false
    const openDataChannel = true
    const useTrickleICE = true
    const widescreen = false

    const resolution = { width: { ideal: 640 }, height: { ideal: 480 } };
    const constraints = {
        video: sendVideo ? resolution : false,
        audio: sendAudio,
    };

    
    const onStatsReport = (stats)=>
    {
        console.log("Stats: "+JSON.stringify(stats, null, 2))
    }
    
    const setupMasterVideo = async ()=>
    {
        // From: https://github.com/awslabs/amazon-kinesis-video-streams-webrtc-sdk-js
        
        const isMaster = true
        
        const iceServers = [];
        
        const onStatsReport = (stats)=>
        {
            console.log("Stats: "+stats)
        }
        if (cleanupData.cleanupVideo)
        {
            cleanupData.cleanupVideo()
            cleanupData.cleanupVideo = null;
        }
        
        const localView = localVideoRef.current
        const remoteView = remoteVideoRef.current
        
        
        const { requestSigner, data: accessData } = await getKinesisAccessData({region, natTraversalDisabled, forceTURN, isMaster, studentId})
                
        try
        {

            console.log('ICE servers: ', accessData.iceServers);        
            let localStream = null
                
            const configuration = {
                iceServers: accessData.iceServers,
                iceTransportPolicy: forceTURN ? 'relay' : 'all',
            };

            // Get a stream from the webcam and display it in the local view. 
            // If no video/audio needed, no need to request for the sources. 
            // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.

            
            
            const master ={
                peerConnectionByClientId: {},
                dataChannelByClientId: {},
                remoteStreams: [],
                peerConnectionStatsInterval: null,
                signalingClient: null
            }            
            cleanupData.closeConnectionsFunc = ()=>
            {
                Object.keys(master.peerConnectionByClientId).forEach(clientId => {
                    master.peerConnectionByClientId[clientId].close();
                    delete master.peerConnectionByClientId[clientId]
                });
                
                if (localStream) {
                    localStream.getTracks().forEach(track => track.stop());
                    //localStream.getTracks().forEach(track => track.enabled = false);
                }
                //CVD localStream = null
                localView.srcObject = null;
                //localVideoRef.current.srcObject = null
                
                // TODO: Is this the right place?
                if (onDisconnected) onDisconnected()                
            }
            
            
            const signalingClientOnSdpOffer = async (offer, remoteClientId, signalingClient) => {
                console.log('[MASTER] Received SDP offer from client: ' + remoteClientId);
                
                //const takeIt = await onIncomingCall(remoteClientId) // TODO: Make this have the callers name/id
                // Choosend to ignore the call
                const peerConnection = new RTCPeerConnection(configuration);
                master.peerConnectionByClientId[remoteClientId] = peerConnection;

                // Send any ICE candidates to the other peer
                peerConnection.addEventListener('icecandidate', ({ candidate }) => {
                    if (candidate) {
                        // When trickle ICE is enabled, send the ICE candidates as they are generated.
                        if (useTrickleICE) {
                            //console.log('[MASTER] Sending ICE candidate to client: ' + remoteClientId);
                            signalingClient.sendIceCandidate(candidate, remoteClientId);
                        }
                    } else {
                        console.log('[MASTER] All ICE candidates have been generated for client: ' + remoteClientId);

                        // When trickle ICE is disabled, send the answer now that all the ICE candidates have ben generated.
                        if (!useTrickleICE) {
                            console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
                            signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
                        }
                    }
                });


                // As remote tracks are received, add them to the remote view
                peerConnection.addEventListener('track', event => {
                    console.log('[MASTER] Received remote track from client: ' + remoteClientId);
                    
                    //remoteView.srcObject = event.streams[0];
                    remoteVideoRef.current.srcObject = event.streams[0];
                });

                
                //console.log("Remote offer: "+JSON.stringify(offer, null, 2))
                await peerConnection.setRemoteDescription(offer);

                const takeIt = await new Promise( resolve => {
                    
                    onConfirmConnection( 
                    ()=>{
                        // Yes
                        resolve(true)
                        
//                        const p = navigator.mediaDevices.getUserMedia(constraints)
//                        p.then( v => resolve( v ))
                    },
                    ()=>{
                        //No
                        resolve(false)
                    })
                })

                const rejectAnswer = async ()=>
                {
                    const tempAnswer = await peerConnection.createAnswer({
                            offerToReceiveAudio: false,
                            offerToReceiveVideo: false,
                        })
                        
                    delete master.peerConnectionByClientId[remoteClientId]
                    
                    await peerConnection.setLocalDescription(tempAnswer);

                    // TODO: I'm sure there is a proper way to reject a 'call', but this is what I did.  The master sends a specific made up SDP answer that we look for.
                    const localDescription = peerConnection.localDescription
                    localDescription.sdp = "NO"    
                    
                    signalingClient.sendSdpAnswer(localDescription, remoteClientId)

                    peerConnection.close()                                        
                }
                if (!takeIt)
                {
                    await rejectAnswer()
                    return                    
                }
                
                localStream = null
                muteFuncRef.current = null
                if ((sendVideo || sendAudio))
                {
                    try {
                        localStream = await navigator.mediaDevices.getUserMedia(constraints);
                        
                    } catch (e) {
                        console.error('[MASTER] Could not find webcam');
                    }
                }          
                
                if (!localStream)
                {
                    console.error("Couldn't get media.  It may not be possible or may have been rejected by the user.");
                    
                    await rejectAnswer()
                    return
                }

                localVideoRef.current.srcObject = localStream;
                
                localStream.getTracks().forEach(track => track.enabled = true) 
                
                muteFuncRef.current = (newValue)=>
                {
                    localStream.getTracks().forEach(track => track.enabled = !newValue);
                }
                

                if (openDataChannel) {
                    master.dataChannelByClientId[remoteClientId] = peerConnection.createDataChannel('kvsDataChannel');
                    peerConnection.ondatachannel = event => {
                        
                        event.channel.onmessage = onRemoteDataMessage;
                        
                        if (onConnected) onConnected(remoteClientId)
                    };
                    master.dataChannelByClientId[remoteClientId].onopen = event =>{
                        
                    }
                    master.dataChannelByClientId[remoteClientId].onclose = event =>{

                        if (cleanupData.closeConnectionsFunc)
                        {
                            cleanupData.closeConnectionsFunc()
                        }
                    }
                    
                    // Send to all connected viewers
                    if (sendMessageRef)
                    {
                        sendMessageRef.current = (message)=>
                        {
                            Object.values(master.dataChannelByClientId).forEach((dataChannel)=>
                            {
                                // Send to all channels that are open
                                if (dataChannel.readyState == "open")
                                {

                                    // Send the full list of all messages
                                    dataChannel.send(JSON.stringify(message))
                                }
                            })
                        }
                    }                    
                    // Trigger a send-to-all
                    (async ()=>{
                        await new Promise(r => setTimeout(r, 1000)); 
                        onRemoteDataMessage({data:null})}
                    )()
                    
                }

                // Poll for connection stats
                // if (!master.peerConnectionStatsInterval) {
                    // master.peerConnectionStatsInterval = setInterval(() => peerConnection.getStats().then(onStatsReport), 1000);
                // }

                // If there's no video/audio, localStream will be null. So, we should skip adding the tracks from it.
                if (localStream) {
                    localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));
                }
                
                // Create an SDP answer to send back to the client
                console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId);
                const answer = await peerConnection.createAnswer({
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: false,
                    })

                //console.log("Local response: "+JSON.stringify(offer, null, 2))
                await peerConnection.setLocalDescription(answer);

                // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
                if (useTrickleICE) {
                    console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
                    signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
                }
                console.log('[MASTER] Generating ICE candidates for client: ' + remoteClientId);
            }

            const signalingClientOnIceCandidate = async (candidate, remoteClientId) => {
                //console.log('[MASTER] Received ICE candidate from client: ' + remoteClientId);

                // Add the ICE candidate received from the client to the peer connection
                const peerConnection = master.peerConnectionByClientId[remoteClientId];
                if (peerConnection)
                {
                    peerConnection.addIceCandidate(candidate);
                }
            }
                                    
            const signalingClientOnClose = async () => {
                console.log('[MASTER] Disconnected from signaling channel');
                

                console.log("Waiting before restarting...")
                await sleep(2000) 
                if (!master.shutdownSignalingClient &&
                     master.createSignalingClient)
                {
                    console.log("Restarting signalingClient")
                    master.createSignalingClient()
                }
            }
           
            const signalingClientOnError = (err) => {
                console.error('[MASTER] Signaling client error'+err);
            }

            cleanupData.cleanupVideo = ()=>
            {
                if (cleanupData.closeConnectionsFunc)
                {
                    cleanupData.closeConnectionsFunc()
                }
                    
                console.log('[MASTER] Stopping master connection');
                if (master.signalingClient) {
                    
                    master.shutdownSignalingClient = true
                    
                    master.signalingClient.close();
                }

                Object.keys(master.peerConnectionByClientId).forEach(clientId => {
                    master.peerConnectionByClientId[clientId].close();
                    delete master.peerConnectionByClientId[clientId]
                });

                if (localStream) {
                    localStream.getTracks().forEach(track => track.stop());
                }

                master.remoteStreams.forEach(remoteStream => remoteStream.getTracks().forEach(track => track.stop()));
                master.remoteStreams = [];

                if (master.peerConnectionStatsInterval) {
                    clearInterval(master.peerConnectionStatsInterval);
                }

                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = null;
                    localView.srcObject = null;
                }

                if (remoteView) {
                    remoteView.srcObject = null;
                }

                if (master.dataChannelByClientId) {
                    master.dataChannelByClientId = {};
                }        
            }
            
            master.createSignalingClient = ()=>{
                master.shutdownSignalingClient = false
                
                // Create Signaling Client
                master.signalingClient = new window.KVSWebRTC.SignalingClient({
                    channelARN: accessData.channelARN,
                    channelEndpoint: accessData.endpointsByProtocol.WSS,
                    role: window.KVSWebRTC.Role.MASTER,
                    region: region,
                    //credentials: {
                    //    accessKeyId: accessKeyId,
                    //    secretAccessKey: secretAccessKey,
                    //},
                    requestSigner,
                    systemClockOffset: accessData.systemClockOffset,
                });
                // Once the signaling channel connection is open, connect to the webcam and create an offer to send to the master
                master.signalingClient.on('open', async () => {
                    console.log('[MASTER] Connected to signaling service');
                });
                master.signalingClient.on('sdpOffer', async (offer, remoteClientId)=>{ 
                    return await signalingClientOnSdpOffer(offer, remoteClientId, master.signalingClient) 
                })
                master.signalingClient.on('iceCandidate', signalingClientOnIceCandidate)
                master.signalingClient.on('close', signalingClientOnClose)
                master.signalingClient.on('error', signalingClientOnError)
                
                console.log('[MASTER] Starting master connection');
                master.signalingClient.open();                     
            }            

            master.createSignalingClient()

            
           
            
        }
        catch(e)
        {
            console.log("Error setting up master video: "+e)
            setVideoServerError(true);               
        }                
    }
    React.useEffect(()=>    
    {        
        if ( localVideoRef.current && remoteVideoRef.current && !videoServerError && region)
        {
            setupMasterVideo()
            
            return ()=>{
                if (cleanupData.cleanupVideo)
                {
                    cleanupData.cleanupVideo()
                }
            }
        }
    },[region, localVideoRef, remoteVideoRef, letsRestart])
    
    const disconnect = ()=>
    {
        if (cleanupData.closeConnectionsFunc)
        {
            cleanupData.closeConnectionsFunc()
        }        
    }
    return {disconnect, videoServerError, setMuted}
}

const VoiceChatView = ({classRegion})=>
{
    const username = distiAuth.getUsername()
    const sendAudio = true
    const sendVideo = false
    const localVideoRef = React.useRef(null);
    const remoteVideoRef = React.useRef(null);
    
    const [showIncomingCallPopup, setShowIncomingCallPopup] = React.useState("")
    const [connectedTo, setConnectedTo] = React.useState("")
    
    const [localMuted, setLocalMuted] = React.useState(false)
    
    const onIncomingCall = async (callSource)=>
    {
        
        setShowIncomingCallPopup(callSource)
        
        // waits for the answer to the request
        return (window.confirm("Accept call from Class Facilitator?"))        
    }
    const onConnected = (who)=>
    {
        setConnectedTo(who)
    }
    const onDisconnected = ()=>
    {
        setConnectedTo("")
    }
    const toggleMute=()=>
    {
        setLocalMuted(current=>!current)
    }  
    const [confirmOpen, setConfirmOpen] = React.useState(null)
    
    const onConfirmConnection= (onYes, onNo)=>
    {
        setConfirmOpen({onYes, onNo})        
    }
    
    const {disconnect, videoServerError, setMuted} = useViewMaster({localVideoRef, remoteVideoRef, 
            studentId: username, 
            sendAudio, 
            sendVideo, 
            onIncomingCall,
            onConnected,
            onDisconnected,
            onConfirmConnection,
            classRegion })

    React.useEffect(()=>
    {
        setMuted(localMuted)
        
    }, [localMuted, setMuted])
    
  return(

    <div style={connectedTo?{display:"initial"}:{display:"none"}}>
          <Grid container spacing={3} >
              <Grid item xs={6}>              
                <Fab color="primary"
                    onClick={toggleMute}>
                    {localMuted? <MicOffIcon/> : <MicIcon/>}
                </Fab>
              </Grid>
              <Grid item xs={6}>  
                <Fab disabled={!(connectedTo)} style={{backgroundColor: "red"}} onClick={()=>{
                    
                    disconnect(connectedTo)
                    
                }}><CallEndIcon fontSize="large"/></Fab>                              
              </Grid>
        </Grid>

          <ConfirmDialog
            title="Accept Facilitator Voice Chat?"
            open={confirmOpen}
            setOpen={setConfirmOpen}
          >
          </ConfirmDialog>
          
        {videoServerError?<h2 style={{width:"300px", position:"absolute", top:"0px", right:"20%"}}>Error accessing video server</h2>:  
        <>
          <br/>  
          {(sendVideo || sendAudio)?<>
              <video hidden autoPlay playsInline muted style={{width:"300px", position:"absolute", top:"0px", right:"20%"}} ref={localVideoRef}/>
              <video hidden autoPlay playsInline style={{width:"300px", position:"absolute", top:"0px", left:"20%"}} ref={remoteVideoRef}/>  
          </>:""}
        </>}
    </div>
    )   
}

export default VoiceChatView