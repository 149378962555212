import React from 'react'
import WebCamRecorder from "./WebCamRecorder"

import distiAuth from "disti-auth.js";

const uniqueIndex = {
    next: 0, 
    max: 3,
}
const getNextUniqueIndex = (allUnique)=>{
    if (allUnique)
    {
        return Math.floor(Date.now() / 1000)
    }
    else
    {
        let rval = uniqueIndex.next++
        if (uniqueIndex.next > uniqueIndex.max)
        {
            uniqueIndex.next = 0
        }
        return rval
    }
}
export default function StudentImageUploader({keepUniqueImages}) {
    

    const [postDataTemplate, setPostDataTemplate] = React.useState(null)
    
    React.useEffect(()=>{
        (async ()=>{
            setPostDataTemplate( await distiAuth.getUserThumbnailUploadUrl({region: distiAuth.runningFromRegion, classId: distiAuth.selectedClass}))
        })()       
    },[])
    
    
    // onNewImage={()=>{console.log("Got new image!")}
    return <WebCamRecorder showPhoto={false} imgType={keepUniqueImages?'png':'jpg'} onNewImage={ async(imageData)=>{

        if (!postDataTemplate)
        {
            return
        }
        
        // If we want to keep them all we can do Date.now() for the unique id
        // Doing round-robin for now
        const uniqueFileName = "webcam_"+getNextUniqueIndex(keepUniqueImages)+"." + (keepUniqueImages?'png':'jpg')
        
        try 
        {
            
            const postData = JSON.parse(JSON.stringify(postDataTemplate))

            postData.fields.key = postData.fields.key.replace("${filename}", uniqueFileName)

            let form = new FormData()
            Object.keys(postData.fields).forEach(key => form.append(key, postData.fields[key]))
            
            form.append('file', imageData)
            console.log("Trying upload")
            await fetch(postData.url, {method: 'POST', body: form})
            
            
            // Now tell the database about the new file                        
            distiAuth.reportUserStatusUserThumbnail({region: distiAuth.runningFromRegion, classId: distiAuth.selectedClass, filename: uniqueFileName})
        }
        catch(e)
        {
            console.log("Issue: "+e)
        }
        
        }}/>
}