import React from "react";

import {Widget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import './student-chat-styles.css';
import distiAuth from 'disti-auth.js'
import KinesisView from './KinesisViewMaster.js';
import ChatBoxStudent from './ChatBoxStudent.js';
import studentChatData from './StudentChatData.js';
import Draggable from 'react-draggable';
import ChatIcon from '@material-ui/icons/Chat';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';

const useTimeout = (callback, delay) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
};

const nextMessageId = {value: 1000}
const lastReadSize = {value: 0}
export default ({classRegion})=>
{
    const [showingChat, setShowingChat] = React.useState(false)
    const {currentData, sendData} = studentChatData.useChatData({studentName: distiAuth.getUsername(), classId: "TODO", pollInterval:5000})

    const username = distiAuth.getUsername()
    const externalMessageCount = currentData.filter(item=>item.who != username).length
    const unreadSize = currentData ? (externalMessageCount - lastReadSize.value) : 0
    
    React.useEffect(()=>
    {
        if (showingChat && unreadSize > 0)
        {
            const id = setTimeout(()=>{lastReadSize.value = externalMessageCount}, 3000)
            return ()=>{clearTimeout(id)}
        }        
    },[unreadSize, showingChat])
    
    const sendMessage = (newMessage)=>{
        const newMessageObject = {
            messageId: nextMessageId.value++,
            who: distiAuth.getUsername(), 
            whoFull: distiAuth.getUserData().fullName, 
            whoType: "STUDENT", 
            what: newMessage,
            when: Date.now(),
        }
        
        return sendData(JSON.stringify(newMessageObject))        
    };

    return (    
    <>
    <div style={{position:"absolute",right:"50px",bottom:"50px"}}>
    {showingChat?
    <Draggable enableUserSelectHack={false}>
    
        <div style={{position: "relative", width: "400px"}}>
            <ChatBoxStudent disabled={false} currentData={currentData || []}  onSend={sendMessage}/>        
        </div>
    </Draggable>: null}
    <br/>
    <Fab style={{float:"right", position:"relative"}} color="primary" aria-label="toggle chat" onClick={()=>
    {
        setShowingChat(!showingChat)            
    }}>
    
    <Badge 
        badgeContent={unreadSize} 
        color="error" 
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }} 
        style={{position: "absolute", right:"0px", top:"0px"}}>
    </Badge>
    <ChatIcon/>
    </Fab>
    </div>
    <div style={{position:"absolute",right:"130px",bottom:"30px"}}>
        <KinesisView classRegion={classRegion}/>
    </div>
    </>
    )
    
}