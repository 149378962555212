import React from "react";

import distiAuth from 'disti-auth.js'


const useInterval = (callback, delay, startNow) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (startNow)
    {
        tick() // Start with an immediate call
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};


const useChatData = ({studentName, classId, pollInterval=5000})=>
{
    const [currentData, setCurrentData] = React.useState([])
    const callback = async ()=>
    {
        const newData = await distiAuth.getCurrentChatDataByStudent( studentName, classId )
        if (newData)
        {
            setCurrentData(newData || [])
        }
        else
        {
            console.log("No data")
        }
    }
    useInterval (callback, pollInterval, true)    
    
    const sendData = async (newMessage)=>
    {
        const newData = await distiAuth.addToCurrentChatDataByStudent( studentName, classId, newMessage )
        if (newData)
        {
            setCurrentData(newData)
            return true
        }
        return false
    }
    return {currentData, sendData}
}

export default {
    useChatData
}